import base62 from 'base62'
import BigNumber from 'bignumber.js';
import { countBy } from 'lodash-es'
import { Keypair } from 'stellar-base';

import { handleResponse, shajs } from './utils';

// TODO
// Our mine vs move method isn't terribly smart.
  // Should prefer identical issuers vs just looking for the same color
  // Should reuse existing palette accounts to save on create and move costs and reduce empty palette accounts
  // If we have an equal match we should use that, assuming the palette account is configured correctly

export default async function({
  type,
  albedo,
  palette,
  wallet,
  account,
  width,
  name,
  description
}) {
  let xdr
  let use

  const index = account.data_attr.royaltyindex ? atob(account.data_attr.royaltyindex) : -1
  const royaltyIndex = base62.encode(index).padStart(7, '0')
  const royaltyHash = await shajs(royaltyIndex)
  const royaltyKeypair = Keypair.fromRawEd25519Seed(royaltyHash)
  const royaltyAccount = royaltyKeypair.publicKey()

  let minePalette = Object.assign([], palette)
  let movePalette = {}
  
  await fetch(`/proxy/palettes?pubkey=${royaltyAccount}`)
  .then(handleResponse)
  .then((accounts) => 
    accounts.forEach(({id, balances}) => {
      balances.forEach(({asset_type, asset_code, balance}) => {
        if (asset_type !== 'native') {
          const hex = base62.decode(asset_code.substring(0, 5)).toString(16).padStart(6, 0)
          const hexBalance = parseInt(new BigNumber(balance).times(10_000_000).toFixed(0, 3))

          new Array(hexBalance).fill(0).forEach(() => {
            const existingIndex = minePalette.findIndex((h) => h === hex)

            if (existingIndex > -1) {
              minePalette.splice(existingIndex, 1)

              if (movePalette[id])
                movePalette[id].push(asset_code)
              else
                movePalette[id] = [asset_code]
            }
          })
        }
      })
    })
  )

  minePalette = countBy(minePalette)

  for (const key in movePalette) {
    movePalette[key] = countBy(movePalette[key])
  }
  
  // Mine and/or move colors as necessary
  await fetch('/contracts/mine', {
    method: 'POST',
    body: JSON.stringify({
      pubkey: wallet.pubkey,
      minePalette,
      movePalette,
      use: 'new'
    })
  })
  .then(handleResponse)
  .then((res) => {
    xdr = res.xdr
    use = res.use
  })
  
  await albedo.tx({
    xdr,
    description: `Setup new Colorglyph palette account`,
    pubkey: wallet.pubkey,
    network: import.meta.env.VITE_STELLAR_NETWORK,
    submit: true,
  })

  xdr = await fetch('/contracts/mint', {
    method: 'POST',
    body: JSON.stringify({
      pubkey: wallet.pubkey,
      use,
      width,
      name,
      description,
      palette
    })
  })
  .then(handleResponse)
  
  await albedo.tx({
    xdr,
    description: `Mint a ${type === 'new' ? type : ''} Colorglyph`,
    pubkey: wallet.pubkey,
    network: import.meta.env.VITE_STELLAR_NETWORK,
    submit: true,
  })
}